import type { BazaarBulletsTableRow } from '@base/components/business/bazaar-bullets-table'
import type { BulletTableRow } from './bullet'
import { TxType } from './transaction'
import type { IBulletTxItemCard } from '@base/components/business/BulletTxItemCard.vue'

export enum TxDialogState {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  HIDDEN = 'HIDDEN',
}

export interface TxPendingData {
  description: string
  steps: {
    title: string
    description: string[]
  }[]
  activeStep: number
}

export interface TxSuccessRowValue {
  value: string
  symbol?: string
  subtext?: string
  url?: string
}

export interface TxSuccessRow {
  label: string
  tooltip?: string
  values: TxSuccessRowValue[]
}

interface TxSuccessCommonData {
  description: string
  primaryRows: TxSuccessRow[]
  secondaryRows: TxSuccessRow[]
  txType: Exclude<TxType, BulletTxType>
  message?: string
  cta: {
    label: string
    url: string
    external?: boolean
  }
}

interface TxSuccessWithTxHash extends TxSuccessCommonData {
  txHash: string
  contract?: never
}

interface TxSuccessWithContract extends TxSuccessCommonData {
  contract: string
  txHash?: never
}

type TxSuccessGeneralData = TxSuccessWithTxHash | TxSuccessWithContract

export const BulletTxTypeList = [TxType.BULLET_CLAIM] as TxType[]

export type BulletTxType = TxType.BULLET_CLAIM

export interface TxSuccessBulletData
  extends Omit<TxSuccessGeneralData, 'txType' | 'primaryRows'> {
  txType: BulletTxType
  primaryRows: BulletTableRow[]
}

export interface TxSuccessBulletCard extends IBulletTxItemCard {
  bulletAddress: string
}
export interface TxSuccessBulletCardData
  extends Omit<TxSuccessGeneralData, 'txType' | 'primaryRows'> {
  txType: TxType.BULLET_SELL | TxType.BULLET_BUY
  primaryRows: TxSuccessBulletCard[]
}

export type TxSuccessData =
  | TxSuccessGeneralData
  | TxSuccessBulletData
  | TxSuccessBulletCardData

export type TxDialogData = TxPendingData | TxSuccessData
