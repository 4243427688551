import { defineStore } from 'pinia'
import {
  TxDialogState,
  type TxSuccessData,
  type TxPendingData,
  type TxDialogData,
} from '@base/types/tx-dialog'

export const useTxDialogStore = defineStore('txDialog', () => {
  const state = ref<TxDialogState>(TxDialogState.HIDDEN)
  const data = ref<TxDialogData | null>(null)

  const open = computed(() => state.value !== TxDialogState.HIDDEN)

  const isCloseEnabled = computed(() => state.value !== TxDialogState.PENDING)

  const closeTxDialog = () => {
    state.value = TxDialogState.HIDDEN
    data.value = null
  }

  function updateTxDialog(
    newState: TxDialogState.PENDING,
    newData: TxPendingData,
  ): void
  function updateTxDialog(
    newState: TxDialogState.SUCCESS,
    newData: TxSuccessData,
  ): void
  function updateTxDialog(newState: TxDialogState.FAILURE): void
  function updateTxDialog(newState: TxDialogState.HIDDEN): void
  function updateTxDialog(newState: TxDialogState, newData?: TxDialogData) {
    state.value = newState
    if (newState === TxDialogState.FAILURE) return
    if (newState === TxDialogState.HIDDEN) {
      closeTxDialog()
      return
    }
    if (!newData) throw new Error('Missing data for state')
    if (newState === TxDialogState.PENDING) {
      data.value = newData
      return
    }
    if (newState === TxDialogState.SUCCESS) {
      data.value = newData
      return
    }
  }

  const incrementActiveStep = () => {
    if (state.value !== TxDialogState.PENDING) return
    if (!data.value) return
    if (
      (data.value as TxPendingData).activeStep ===
      (data.value as TxPendingData).steps.length - 1
    ) {
      return
    }
    ;(data.value as TxPendingData).activeStep += 1
  }

  return {
    open,
    state,
    data,
    isCloseEnabled,
    closeTxDialog,
    updateTxDialog,
    incrementActiveStep,
  }
})
