import type { Token } from '@base/types/tokens'
import type { BulletType, SniperType } from '@deorderbook/shared'

export enum TxType {
  APPROVE = 'APPROVE',
  DEORDER = 'DEORDER',
  WITHDRAW = 'WITHDRAW',
  HODL_STAKE = 'HODL_STAKE',
  SNIPER_STAKE = 'SNIPER_STAKE',
  HODL_UNSTAKE = 'HODL_UNSTAKE',
  SNIPER_UNSTAKE = 'SNIPER_UNSTAKE',
  UNWIND = 'UNWIND',
  EXERCISE = 'EXERCISE',
  COLLECT = 'COLLECT',
  MINT = 'MINT',
  REDEEM = 'REDEEM',
  LOCK = 'LOCK',
  CLAIM = 'CLAIM',
  DOB_CLAIM = 'DOB_CLAIM',
  BULLET_CLAIM = 'BULLET_CLAIM',
  BULLET_SELL = 'BULLET_SELL',
  BULLET_UPDATE = 'BULLET_UPDATE',
  BULLET_CANCEL = 'BULLET_CANCEL',
  BULLET_BUY = 'BULLET_BUY',
  CLAIM_COLLECT = 'CLAIM_COLLECT',
  HODL_UNSTAKE_CLAIM = 'HODL_UNSTAKE_CLAIM',
  SNIPER_UNSTAKE_CLAIM = 'SNIPER_UNSTAKE_CLAIM',
  CREATE_BLANK_OPTIONS = 'CREATE_BLANK_OPTIONS',
  CREATE_OPTIONS = 'CREATE_OPTIONS',
  REACTIVATE_OPTIONS = 'REACTIVATE_OPTIONS',
  DEACTIVATE_OPTIONS = 'DEACTIVATE_OPTIONS',
  UPDATE_STRIKE_PRICES = 'UPDATE_STRIKE_PRICES',
}

export interface TxModalRowCaption {
  label: string
  token?: Token
  optionInfo?: {
    strike: string
    expiry: string
  }
}
export interface TxModalRowValue {
  value: string
  subvalue?: string
  token?: Token
  caption?: TxModalRowCaption
  link?: string
}

export interface TxModalRow {
  label?: string
  optionInfo?: {
    strike: string
    expiry: string
    token: SniperType | BulletType
    nickname?: string
  }
  values: TxModalRowValue[]
}

export interface TxModalData {
  type: TxType
  token?: Token
  rows?: TxModalRow[]
  multiRows?: TxModalRow[][]
  onSubmit?: () => void
  onCancel?: () => void
}

export enum TxModalState {
  CONFIRM = 'CONFIRM',
  PENDING = 'PENDING',
  HIDDEN = 'HIDDEN',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum DeOrderType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface TxModalSuccessActionButtonParams {
  text: string
  url: string
}

export interface TxModalSuccessOptions {
  showAccountLink?: boolean
  showExplorerLink?: boolean
  actionButton?: TxModalSuccessActionButtonParams
}
